import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'
import EmitterContext from '../../../context/Emitter'
import PriceEmitterContext from '../../../context/PriceEmitter'
import ListGroup from 'react-bootstrap/ListGroup';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

//jquerry params
import { param } from 'jquery'


import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import api from '../../../api/api';

const OortHistory = () => {
    const [loading, setLoading] = useState(false);
    //compute price
    const [SearchHistory, setSearchHistory] = useState([])

    // eslint-disable-next-line
    const [error, setError] = useState();

    const default_columns = [
        { dataField: 'search_time', text: 'Date/Time' },
        //{ dataField: 'user_id', text: 'User', sort: true },
        // { dataField: 'company_id', text: 'Company', sort: true },
        { dataField: 'instance_type', text: 'Instance Type' },
        { dataField: 'ondemand_cost_price', text: 'Ondemand ($/hrs)' },
        { dataField: 'cpu', text: 'CPU' },
        { dataField: 'memory', text: 'Memory' },

    ];

    const defaultSorted = [{
        dataField: 'search_time',
        order: 'desc',
    }];

    function getHistory() {
        try {
            api.GetSearchHistory()
                .then(res => {
                    if (res.length !== 0) {
                        setSearchHistory(res.data.data[0])
                        setLoading(false);
                        setError(null)
                    }
                })
        } catch (err) {
            setError(err);

        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getHistory()
        return () => {
            setSearchHistory({}); // This worked for me
        };
    }, [])

    // useEffect(() => {
    //     const getHistory = () => {
    //         try {
    //             api.GetSearchHistory()
    //                 .then(res => {
    //                     if (res.length !== 0) {
    //                         setSearchHistory(res.data.data[0])
    //                         setLoading(false);
    //                         setError(null)
    //                     }
    //                 })
    //         } catch (err) {
    //             setError(err);

    //         }
    //         finally {
    //             setLoading(false);
    //         }
    //     }
    //     getHistory()
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>

            <Col lg="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h6 className="card-title">User - Search History Log</h6>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <ToolkitProvider
                            bootstap4
                            keyField="id"
                            data={SearchHistory}
                            columns={default_columns}

                        >
                            {
                                props => (
                                    <>


                                        <BootstrapTable
                                            {...props.baseProps}
                                            keyField="id"
                                            defaultSorted={defaultSorted}
                                            data={SearchHistory}
                                            striped bordered hover
                                            noDataIndication="Please Start with the Search"
                                            pagination={paginationFactory({ sizePerPage: 5 })}
                                            columns={default_columns}
                                        />
                                    </>
                                )
                            }
                        </ToolkitProvider>
                    </Card.Body>
                </Card>
            </Col>






        </>
    )

}

export default OortHistory;