import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import EleComputeList from './elecompute/elecomputelist'

//progressbar
import Progress from '../../../components/progress.js'
//Count-up
import CountUp from 'react-countup';
import OortCompairsonPricing from './oortcompairson'

//Auth Route
import { useHistory } from "react-router-dom";
import AuthRoute from '../../../_helpers/route'

//
import Loader from '../../../components/Loader'
//header
import Header from '../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../components/partials/dashboard/FooterStyle/footer'
import SettingOffcanvas from '../../../components/partials/components/settingoffcanvas'
import BtnCldElements from './btncldelements'

import EmitterContext from '../../../context/Emitter'

//jquerry params
import { param } from 'jquery'

//Api
import api from '../../../api/api';
import ComputeOpt from './elecompute/computeopt'

const OortEstimator = () => {
    const [show, AccountShow] = useState('A');
    const history = useHistory();

    const onClickOortDashboard = () => {
        history.push(AuthRoute.OORT_DASHBOARD)
    }

    const [cloud, setCloud] = useState([]);
    const [cloudId, setCloudId] = useState('');
    const [instanceType, setinstanceType] = useState([]);
    const [region, setRegion] = useState([]);
    const [coreCpu, setCoreCpu] = useState([])
    const [coreMemory, setCoreMemory] = useState([])
    const [userSelectedCloudName, setUserSelectedCloudName] = useState("")
    const [userSelectedInstanceType, setUserSelectedInstanceType] = useState("")

    const [selectedCpu, setSelectedCpu] = useState("")
    const [selectedMemory, setSelectedMemory] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")

    const [fetchedResourrce, setFetchedResourrce] = useState([])

    useEffect(() => {
        api.GetCloud()
            .then((req) => {
                setCloud(req.data);
            })
    }, [cloudId])

    const getCpuResponse = (event) => {
        setSelectedCpu(event.target.value)
    }

    const getMemResponse = (event) => {
        setSelectedMemory(event.target.value)
    }

    const getRegion = (event) => {
        setSelectedRegion(event.target.value)
    }

    const handlecloud = (event) => {
        const getcloudid = event.target.value;
        setCloudId(getcloudid);
    }

    useEffect(() => {
        if (selectedCpu.length !== 0 && selectedMemory.length !== 0 && selectedCpu !== '--select cpu--' && selectedMemory !== '--select memory--' && userSelectedCloudName.length!== 0 && userSelectedInstanceType.length !== 0 && selectedRegion.length !== 0 && userSelectedCloudName !== '--select cloud--'  && userSelectedInstanceType !== '--select instance type--' && selectedRegion !== '--select region--') {
           api.GetCloudData(userSelectedCloudName, userSelectedInstanceType, param({cpu: selectedCpu, memory: selectedMemory, region: selectedRegion}))
           .then((res) => {
            setFetchedResourrce(res.data);
            })
        }
    },[selectedCpu, selectedMemory, userSelectedCloudName, userSelectedInstanceType, selectedRegion])

    const getUserResponse = (event) => {
        const cloud_name = cloud[cloudId - 1]["cloud"]
        const instance_type = cloud_name + "_" + event.target.value.split(' ').join('_')
        setUserSelectedCloudName(cloud_name)
        setUserSelectedInstanceType(instance_type)
    }
    
    useEffect(() => {
        //get cpu and memory
        if (userSelectedCloudName.length !== 0 && userSelectedInstanceType.length !== 0) {
            api.GetRoot(userSelectedCloudName, userSelectedInstanceType)
                .then((res) => {
                    setCoreCpu(res.data["CPU (CORE)"]);
                    setCoreMemory(res.data["MEMORY (GB)"]);
                })
        }
    },[userSelectedCloudName, userSelectedInstanceType])

    useEffect(() => {
        if (cloudId.length !== 0) {
            //instance type
            api.GetInstanceType(cloudId)
                .then((res) => {
                    setinstanceType(res.data);
                })
            //region    
            api.GetRegion(cloudId)
                .then((res) => {
                    setRegion(res.data);
                })
        }

    }, [cloudId]);

    //.map(text => ({ label: text, value: text }))
    return (
        <>
            <Loader />
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    < Header />
                    <SubHeader />
                    <div className="py-0 conatiner-fluid content-inner mt-n5" >
                        <BtnCldElements />
                        <ComputeOpt />


                    </div>
                    <Footer />
                </div>
            </main>
            <SettingOffcanvas />
        </>
    )
}

export default OortEstimator
