import { service } from '../utils/auth';
import AuthRoute from '../_helpers/route';
import { setHeaders } from '../utils/auth';

const api = {
    UserprofileGet :  async () => {
        return await service.get(AuthRoute.APP_ME, { headers: setHeaders() })
    },

    UserprofileUpdate: async (data) => {
        return await service.patch(AuthRoute.APP_ME, data, { headers: setHeaders() })
    },

    GetCloud: async () => {
        return await service.get(AuthRoute.GET_CLOUD, { headers: setHeaders() })
    },

    GetInstanceType: async (id) => {
        return await service.get(AuthRoute.GET_INSTANCE_TYPE + id, { headers: setHeaders() })
    },
    
    GetRegion: async (id) => {
        return await service.get(AuthRoute.GET_REGION + id, { headers: setHeaders() })
    },
    GetRoot: async (cloud_name, type) => {
        return await service.get(AuthRoute.GET_ROOT + cloud_name + "/" + type + "/", { headers: setHeaders() })
    },
    GetCloudData: async (cloud_name, type, data) => {
        // eslint-disable-next-line 
        return await service.post(AuthRoute.GET_ROOT + cloud_name + "/" + type + "/" + "ai" + "?" + data, {}, { headers: setHeaders(), 'Content-Type': 'application/json' })
    },
    GetCloudPrice:  async (cloud_name, type, region, data) => {
        // eslint-disable-next-line 
        return await service.post(AuthRoute.GET_ROOT + cloud_name + "/" + type + "/" + region + "/" + "price" + "?" + data, {}, { headers: setHeaders(), 'Content-Type': 'application/json' })
    },

    PostSearchHistory: async (data) => {
        // eslint-disable-next-line
        return await service.post(AuthRoute.GET_Search_History, data, { headers: setHeaders(), 'Content-Type': 'application/json' })

    },

    GetSearchHistory: async () => {
        // eslint-disable-next-line
        return await service.get(AuthRoute.GET_Search_History, { headers: setHeaders(), 'Content-Type': 'application/json' })

    },
}

export default api;