import React, { useEffect } from 'react'
import { bindActionCreators } from "redux"
// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../../store/setting/setting'
import { connect } from "react-redux"
//header
import Header from '../../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../../components/partials/dashboard/FooterStyle/footer'
import Loader from '../../../../components/Loader'
import SettingOffcanvas from '../../../../components/partials/components/settingoffcanvas'
import { useHistory } from "react-router-dom";
import AuthRoute from '../../../../_helpers/route'

import { Row, Col, Nav, Tab, } from 'react-bootstrap'
import Card from '../../../../components/Card'

//OORTHome
import BtnCldElements from '../btncldelements'

//Loader
import Spinner from 'react-bootstrap/Spinner';










const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),

    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})




const ComputeBtnMenu = (props) => {
    const history = useHistory();

    useEffect(() => {
        //   darkmode
        const colorMode = sessionStorage.getItem('color-mode');
        if (colorMode === null) {
            props.ModeAction(props.darkMode);
        }
        else {
            props.ModeAction(colorMode);
        }

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        // rtlmode
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.SchemeDirAction(props.schemeDirMode)
        }
        else {
            props.SchemeDirAction(rtlMode);
        }




    })

    const onClickComBtnMenu = () => {
        history.push(AuthRoute.OORT_DASHBOARD)
    }
    const onClickComputeOpt = () => {
        history.push(AuthRoute.COMPUTE_OPT)
    }
    const onClickFPGAInst = () => {
        history.push(AuthRoute.FPAGA_INST)
    }
    const onClickGENPURP = () => {
        history.push(AuthRoute.GEN_PURP)
    }
    const onClickGpuInst = () => {
        history.push(AuthRoute.GPU_INST)
    }
    const onClickMedAccinst = () => {
        history.push(AuthRoute.MED_ACC_INST)
    }
    const onClickMemoryOpt = () => {
        history.push(AuthRoute.MEMORY_OPT)
    }
    const onClickMicroInst = () => {
        history.push(AuthRoute.MICRO_INST)
    }
    const onClickMLASICInst = () => {
        history.push(AuthRoute.MLAI_CINST)
    }
    const onClickStorageOpt = () => {
        history.push(AuthRoute.STORAGE_OPT)
    }



    return (
        <>
            <Spinner animation="border" variant="primary" />
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    < Header />
                    <SubHeader />
                    <div className="py-0 conatiner-fluid content-inner mt-n5">
                        <BtnCldElements />  

                        <Tab.Container defaultActiveKey="first">
                            <Row>
                                <Col lg="12">

                                    <Card>
                                        <Card.Body>
                                            <div>
                                                <Row>

                                                    <Col lg="4">
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link ><h4 onClick={onClickComputeOpt}>Compute Optimize</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickFPGAInst}>FPGA Instance</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickGENPURP}>General Purpose</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </Col>
                                                    <Col lg="4">
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickGpuInst}>GPU Instance</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickMedAccinst}>Media Accelerator</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickMemoryOpt}>Memory Optimize</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </Col>
                                                    <Col lg="4">
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickMicroInst}>Micro Instance</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickMLASICInst}>ML Asic Instance</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card border-bottom border-4 border-0 border-primary">
                                                            <div className="card-body ">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Nav.Link><h4 onClick={onClickStorageOpt}>Storage Optimize</h4></Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </Col>


                                                </Row>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                                    <Nav.Item as="li">
                                                        <Nav.Link eventKey="second" onClick={onClickComBtnMenu}>Dashboard</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>






                </div>

                <Footer />
            </main>
            <SettingOffcanvas />




        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ComputeBtnMenu)
