import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ProtectedRoute } from '../utils/auth'
// auth
import ConfirmMail from '../views/dashboard/auth/confirm-mail'
import LockScreen from '../views/dashboard/auth/lock-screen'
import Recoverpw from '../views/dashboard/auth/recoverpw'
import SignIn from '../views/dashboard/auth/sign-in'
import SignUp from '../views/dashboard/auth/sign-up'
import UserProfile from '../views/dashboard/cruduser/user-profile';
import UserAccountSetting from '../views/dashboard/cruduser/user-profile-edit'

// errors
import Error404 from '../views/dashboard/errors/error404'
import Error500 from '../views/dashboard/errors/error500'
import Maintenance from '../views/dashboard/errors/maintenance'
import OortHome from '../views/dashboard/oort/oorthome'

//legal
import PrivacyPolicy from '../views/dashboard/legal/privacy-policy';
import TermsofService from '../views/dashboard/legal/terms-of-service';

//transitions 
import { TransitionGroup, CSSTransition } from "react-transition-group";

//BtnCldElements
import btncldelements from '../views/dashboard/oort/btncldelements.js'

// Compute routes 
import ComputeBtnMenu from '../views/dashboard/oort/elecompute/computebtnmenu'
import ComputeOpt from '../views/dashboard/oort/elecompute/computeopt'
import FPGAInst from '../views/dashboard/oort/elecompute/fpgainst'
import GenPurp from '../views/dashboard/oort/elecompute/genpurp'
import GPUInst from '../views/dashboard/oort/elecompute/gpuinst'
import MedAccInst from '../views/dashboard/oort/elecompute/medaccinst'
import MemoryOpt from '../views/dashboard/oort/elecompute/memoryopt'
import MicroInst from '../views/dashboard/oort/elecompute/microinst'
import MLAICinst from '../views/dashboard/oort/elecompute/mlasicinst'
import StorageOpt from '../views/dashboard/oort/elecompute/storageopt'
import EleComputeList from '../views/dashboard/oort/elecompute/elecomputelist'
import OortCompairsonPricing from '../views/dashboard/oort/oortcompairson'
import MVDashboard from '../views/dashboard/mvdashboard'
import OortEstimator from '../views/dashboard/oort/oortestimator'
import BtnMVDashboard from '../views/dashboard/btnmvdashboard'
import UserEstimatorLog from '../views/dashboard/cruduser/userestimatorlog'
import ComingSoon from '../utils/comingsoon'
import UserUpdatePassword from '../views/dashboard/cruduser/user-update-password'
import OortHistory from '../views/dashboard/oort/oorthistory'
//helpers
import Api_Toast from '../_helpers/api_toast'


const SimpleRouter = () => {
    return (
        <>
            <TransitionGroup>
                <CSSTransition classNames="fadein" timeout={300}>
            <Switch>

                {/* auth */}
                        <Route exact path="/auth/confirm-mail" component={ConfirmMail} />
                        <Route exact path="/auth/lock-screen" component={LockScreen} />
                        <Route exact path="/auth/recoverpw" component={Recoverpw} />
                        <Route exact path="/" component={SignIn} />
                        <Route exact path="/auth/sign-up" component={SignUp} />
                        <ProtectedRoute exact path="/dashboard" component={MVDashboard}  ></ProtectedRoute>
                        <ProtectedRoute exact path="/views/dashboard/oort/oorthome" component={OortHome}  ></ProtectedRoute>

                {/* error */}
                        <Route exact path="/errors/error404" component={Error404} />
                        <Route exact path="/errors/error500" component={Error500} />
                        <Route exact path="/errors/maintenance" component={Maintenance} />
                        <Route exact path="/utils/comingsoon" component={ComingSoon} />

                        <Route path="/dashboard/extra/privacy-policy" exact component={PrivacyPolicy} />
                        <Route path="/dashboard/extra/terms-of-service" exact component={TermsofService} />
                        <ProtectedRoute path="/dashboard/app/user-profile" exact component={UserProfile} />
                        <ProtectedRoute path="/views/dashboard/cruduser/user-profile-edit" exact component={UserAccountSetting} />
                        <ProtectedRoute path="/views/dashboard/cruduser/user-update-password" exact component={UserUpdatePassword} />
                        <ProtectedRoute path="/views/dashboard/oort/btncldelements" exact component={btncldelements} />

                        {/*Compute Element Routes   */}
                        <Route path="/views/dashboard/oort/elecompute/computebtnmenu" exact component={ComputeBtnMenu} />
                        <Route path="/views/dashboard/oort/elecompute/computeopt" exact component={ComputeOpt} />
                        <Route path="/views/dashboard/oort/elecompute/fpgainst" exact component={FPGAInst} />
                        <Route path="/views/dashboard/oort/elecompute/genpurp" exact component={GenPurp} />
                        <Route path="/views/dashboard/oort/elecompute/gpuinst" exact component={GPUInst} />
                        <Route path="/views/dashboard/oort/elecompute/medaccinst" exact component={MedAccInst} />
                        <Route path="/views/dashboard/oort/elecompute/memoryopt" exact component={MemoryOpt} />
                        <Route path="/views/dashboard/oort/elecompute/microinst" exact component={MicroInst} />
                        <Route path="/views/dashboard/oort/elecompute/mlasicinst" exact component={MLAICinst} />
                        <Route path="/views/dashboard/oort/elecompute/storageopt" exact component={StorageOpt} />
                        <Route path="/views/dashboard/oort/elecompute/elecomputelist" exact component={EleComputeList} />
                        <Route path="/views/dashboard/oort/oortcompairson" exact component={OortCompairsonPricing} />
                        <ProtectedRoute path="/views/dashboard/oort/oortestimator" exact component={OortEstimator} />
                        <Route path="/views/dashboard/btnmvdashboard" exact component={BtnMVDashboard} />
                        <Route path="/views/dashboard/cruduser/userestimatorlog" exact component={UserEstimatorLog} />
                        <Route path="/views/dashboard/oort/oorthistory" exact component={OortHistory} />

                        {/* Helpers */}
                        <Route path="/_helpers/api_toast" exact component={Api_Toast} />

                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

export default SimpleRouter
