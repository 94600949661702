import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Auth Route
import { useHistory } from "react-router-dom";
import AuthRoute from '../_helpers/route'

function ComingSoon() {
    const history = useHistory();
    const [shown, setShow] = useState(true);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const onClickComingSoon = () => {
        setShow(false)
        history.push(AuthRoute.OORT_ESTIMATOR)

    }

    return (
        <>
            <Modal
                show={shown}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "50px" }} id="contained-modal-title-vcenter" >
                        &#129409;
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="secondary">
                        <Alert.Heading>Our team is working hard on this feature.
                            <p></p>
                            <h4>Please bear with us, while we build this efficiency! </h4>
                        </Alert.Heading>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClickComingSoon}>Close</Button>
                </Modal.Footer>


            </Modal>
        </>
    );
}

export default ComingSoon;