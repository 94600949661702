
import React, { useState } from 'react'
import { Col, Row, Table, Card, Button } from 'react-bootstrap'

const wrong = <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 20L20 4M20 20L4 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
</svg>

const right = <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 7L6.44526 17.8042C5.85082 18.1921 5.0648 17.9848 4.72059 17.3493L1 10.4798" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
</svg>

const OortCompairsonPricing = () => {
    const [pricing, setPricing] = useState('pricing')
    const pricingLists = [
        {
            title: 'Feature 1',
            icon: right,
            icon1: right,
            icon2: right,
            icon3: right
        },
        {
            title: 'Feature 2',
            icon: wrong,
            icon1: right,
            icon2: right,
            icon3: right
        },
        {
            title: 'Feature 3',
            icon: wrong,
            icon1: right,
            icon2: right,
            icon3: right
        },
        {
            title: 'Feature 4',
            icon: right,
            icon1: right,
            icon2: right,
            icon3: right
        },
        {
            title: 'Feature 5',
            icon: wrong,
            icon1: wrong,
            icon2: right,
            icon3: right
        }
    ]
    return (
        <div>
            <Row>
                <Col md="12">
                    <Row className=" row-cols-1 row-cols-md-2 row-cols-lg-4 mb-3 text-center">
                        <Col>
                            <Card className=" mb-4 rounded-3 ">
                                <Card.Body>
                                    <Card.Title as="h1" className=" pricing-card-title">$0<small className="text-muted fw-light">/mo</small></Card.Title>
                                    <h4 className="my-0 fw-normal mt-3">Free</h4>
                                    <ul className="list-unstyled my-3 p-0">
                                        <li><p>10 users included</p></li>
                                        <li><p>2 GB of storage</p></li>
                                        <li><p>Email support</p></li>
                                        <li><p>Help center access</p></li>
                                    </ul>
                                    <button type="button" className="btn btn-outline-secondary disabled">Sign up for free</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className=" mb-4 rounded-3 ">
                                <Card.Body>
                                    <Card.Title as="h1" className=" pricing-card-title">$15<small className="text-muted fw-light">/mo</small></Card.Title>
                                    <h4 className="my-0 fw-normal mt-3">Pro</h4>
                                    <ul className="list-unstyled my-3 p-0">
                                        <li><p>20 users included</p></li>
                                        <li><p>10 GB of storage</p></li>
                                        <li><p>Priority email support</p></li>
                                        <li><p>Help center access</p></li>
                                    </ul>
                                    <button type="button" className="btn btn-outline-primary">Get started</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className=" mb-4 rounded-3 ">
                                <Card.Body>
                                    <Card.Title as="h1" className=" pricing-card-title">$29<small className="text-muted fw-light">/mo</small></Card.Title>
                                    <h4 className="my-0 fw-normal mt-3">Enterprise</h4>
                                    <ul className="list-unstyled my-3 p-0">
                                        <li><p>30 users included</p></li>
                                        <li><p>15 GB of storage</p></li>
                                        <li><p>Call and email support</p></li>
                                        <li><p>Help center access</p></li>
                                    </ul>
                                    <button type="button" className="btn btn-primary">Get Started</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className=" mb-4 rounded-3 ">
                                <Card.Body>
                                    <Card.Title as="h1" className=" pricing-card-title">$49<small className="text-muted fw-light">/mo</small></Card.Title>
                                    <h4 className="my-0 fw-normal mt-3">Premium</h4>
                                    <ul className="list-unstyled my-3 p-0">
                                        <li><p>50 users included</p></li>
                                        <li><p>60 GB of storage</p></li>
                                        <li><p>24 X 7 call support</p></li>
                                        <li><p>Help center access</p></li>
                                    </ul>
                                    <button type="button" className="btn btn-primary">Get Started</button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}

export default OortCompairsonPricing
