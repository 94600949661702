import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AlertDismissible() {
    const [shown, setShow] = useState(true);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (
        <>
            <Modal
                show={shown}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "50px" }} id="contained-modal-title-vcenter" >
                        &#129409;
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">
                        <Alert.Heading>Oh snap! You got an error!
                            <p></p>
                            <p>No pricing data available, try again with different options! </p>
                        </Alert.Heading>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>


            </Modal>
        </>
    );
}

export default AlertDismissible;