import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VerticalNav from '../SidebarStyle/vertical-nav'
import Scrollbar from 'smooth-scrollbar'
import { bindActionCreators } from "redux"
import { CapitalizeFirstLetter } from '../../../../utils/capitalize'
import { useHistory } from "react-router-dom";
import api from '../../../../api/api'
// import SidebarDark from '../../components/settingoffcanvas'

// export const SidebarDark =() =>{

// }

// store
import { NavbarstyleAction, getDirMode, SchemeDirAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../../store/setting/setting'
import {connect} from "react-redux"

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
        },
        dispatch
    )
})


const Sidebar = (props) => {
    // eslint-disable-next-line
    const history = useHistory();
    const [userProfile, setuserProfile] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [error, setError] = useState();
    
    
    
    useEffect(
        () =>{
            // sidebarcolormode
            const sidebarcolorMode1 = sessionStorage.getItem('sidebarcolor-mode');
            if(sidebarcolorMode1===null){
                props.SidebarColorAction(props.sidebarcolorMode);
            }
            else{
                props.SidebarColorAction(sidebarcolorMode1);
            }

            // sidebarstylemode
            const sidebarstyleMode = sessionStorage.getItem('sidebarstyle-mode');
            if(sidebarstyleMode===null){
                props.SidebarActiveStyleAction(props.sidebaractivestyleMode);
            }
            else{
                props.SidebarActiveStyleAction(sidebarstyleMode);
            }
            Scrollbar.init(document.querySelector('#my-scrollbar'))

            const getUser = () => {
                try {
                    api.UserprofileGet()
                        .then(response => {
                            if (response.data.length !== 0) {
                                setuserProfile(response.data)
                                setLoading(false);
                                setError(null)
                            }
                        })
                } catch (err) {
                    setError(err);
                    setuserProfile(null);
                }
                finally {
                    setLoading(false);
                }
            }
            getUser()


        },[props]
    )
    const minisidebar =() =>{
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }
    if (userProfile.length !== 0) {
        sessionStorage.setItem('first_name', userProfile.first_name !== null ? CapitalizeFirstLetter(userProfile.first_name) : "Howdy")
        sessionStorage.setItem('last_name', userProfile.last_name !== null ? CapitalizeFirstLetter(userProfile.last_name) : "Last Name")
        sessionStorage.setItem('company_name', userProfile.company_name !== null ? CapitalizeFirstLetter(userProfile.company_name) : "Comapany Name")
    } 

    return (
        <>
            <aside className="sidebar sidebar-default navs-rounded-all {{ sidebarVariants }}">
                <div className="sidebar-header d-flex align-items-center justify-content-start">
                    {/* <Link to="/dashboard" className="navbar-brand"> */}
                    {/* Wilfred Comment - Enable the above link in Version 2   */}
                    <Link to="/views/dashboard/oort/oorthome" className="navbar-brand">
                        <svg width="30" height="30" fill="currentColor" className="bi bi-stars" viewBox="0 0 16 16">
                            <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
                        </svg>

                        <h4 className="logo-title">{sessionStorage.getItem('company_name')}</h4>
                    </Link>
                    <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar} >
                        <i className="icon">
                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </i>
                    </div>
                </div>
                <div className="pt-0 sidebar-body data-scrollbar" data-scroll="1" id="my-scrollbar">
                    {/* sidebar-list class to be added after replace css */}
                    <div className="sidebar-list navbar-collapse" id="sidebar">
                      <VerticalNav />
                    </div>
                </div>
                <div className="sidebar-footer"></div>
            </aside>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

