const AuthRoute = {
    "APP_REGISTER": "/auth/register",
    "APP_LOGIN": "/auth/user/login",
    "OORT_LOGIN": "/",
    "MV_DASHBOARD": "/views/dashboard/mvdashboard",
    "OORT_DASHBOARD": "/views/dashboard/oort/oorthome",
    "APP_ME": "/users/me",
    "GET_CLOUD": "/res/cloud/",
    "GET_REGION": "/res/cloud/reg/",
    "GET_INSTANCE_TYPE": "/res/cloud/inst/",
    "GET_Search_History": "/res/searchtype/ai/",
    "GET_ROOT": "/res/",
    "OORT_USER_PROFILE": "/dashboard/app/user-profile",
    "COMPUTE_BTN_MENU": "/views/dashboard/oort/elecompute/computebtnmenu",
    "COMPUTE_OPT": "/views/dashboard/oort/elecompute/computeopt",
    "FPAGA_INST": "/views/dashboard/oort/elecompute/fpgainst",
    "GEN_PURP": "/views/dashboard/oort/elecompute/genpurp",
    "GPU_INST": "/views/dashboard/oort/elecompute/gpuinst",
    "MED_ACC_INST": "/views/dashboard/oort/elecompute/medaccinst",
    "MEMORY_OPT": "/views/dashboard/oort/elecompute/memoryopt",
    "MICRO_INST": "/views/dashboard/oort/elecompute/microinst",
    "MLAI_CINST": "/views/dashboard/oort/elecompute/mlasicinst",
    "STORAGE_OPT": "/views/dashboard/oort/elecompute/storageopt",
    "ELE_COMPUTE_LIST": "/views/dashboard/oort/elecompute/elecomputelist",
    "OORTCOMPAIRSONPRICING": "/views/dashboard/oort/oortcompairson",
    "OORT_ESTIMATOR": "/views/dashboard/oort/oortestimator",
    "BTN_MV_DASHBOARD": "/views/dashboard/btnmvdashboard",
    "USER_ESTIMATOR_LOG": "/views/dashboard/cruduser/userestimatorlog",
    "COMING_SOON": "/utils/comingsoon",
    "USER_ACCOUNT_SETTING": "/views/dashboard/cruduser/user-profile-edit",
    "USER_UPDATE_PASSWORD": "/views/dashboard/cruduser/user-update-password",
    "API_TOAST": "/_helpers/api_toast",


}

export default AuthRoute