import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../../components/Card'
import EmitterContext from '../../../../context/Emitter'
import PriceEmitterContext from '../../../../context/PriceEmitter'
import ListGroup from 'react-bootstrap/ListGroup';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

//jquerry params
import { param } from 'jquery'
//Below imports are to filter by column
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//to use above import, follow below example
//{ dataField: 'operatingSystem', text: 'Operating System', sort: true, filter: textFilter() },
//in jsx <BootstrapTable bootstrap4 keyField='id' data={instancelist} columns={default_columns} defaultSorted={defaultSorted} expandRow={ expandRow } pagination={ paginationFactory() } filter={ filterFactory() } search/>
// in cost,
// const defaultSorted = [{
//     dataField: 'instanceType',
//     order: 'desc',
//     filter: textFilter()
//   }];

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import api from '../../../../api/api';

const EleComputeList = () => {
    const cloudFetchedData = useContext(EmitterContext)
    const instancelist = cloudFetchedData
    const { SearchBar } = Search;
    const default_columns = [
        //{ dataField: 'id', text: 'Id', sort: true },
        { dataField: 'operatingSystem', text: 'Operating System', sort: true },
        { dataField: 'instanceType', text: 'Instance Type', sort: true },
        { dataField: 'tenancy', text: 'Tenancy', sort: true },
    ];

    const defaultSorted = [{
        dataField: 'operatingSystem',
        order: 'asc',
    }];

    const expandRow = {
        renderer: (row, rowIndex) => (
            <>
                <Card>
                        <ListGroup>
                        <ListGroup.Item >
                                <b>System Information</b>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>CPU:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row['vcpu']}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Memory:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row['memory']}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Clock Speed:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row['clockSpeed']} </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Capacity Status:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row.capacitystatus} </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Storage:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row['storage']}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>EBS Throughput:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row.dedicatedEbsThroughput} </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Licence Model:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row.licenseModel} </span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Network Performance:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row['networkPerformance']}</span>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span><b>Physical Processor:</b></span> {' '}
                                <span style={{ marginLeft: '.5rem' }}> {row.physicalProcessor} </span>
                            </ListGroup.Item>
                    </ListGroup>
                </Card>

            </>
        ),
        showExpandColumn: true,
        //className: "justify-content-between"
    };
    
    const {checkBox, setCheckBox} = useContext(PriceEmitterContext)
   
    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        title: 'TEST',
        className: "justify-content-center",
        onSelect: (row, isSelect, rowIndex, e) => {
        setCheckBox({
             os: row["operatingSystem"], 
             capacitystatus: row["capacitystatus"], 
             tenancy: row["tenancy"], 
             preInstalledSw: row["preInstalledSw"], 
             licenseModel: row["licenseModel"],
             instanceType: row["instanceType"]
        })
        },
        selectColumnStyle: ({
          checked,
          disabled,
          rowIndex,
          rowKey
        }) => {
          if (checked) {
              return {

              backgroundColor: 'transperent'
            };
          }
          return {
            backgroundColor: 'transperent'
          };
        }

    };

    return (
        <>

            <ToolkitProvider
                bootstap4
                keyField="id"
                data={ instancelist }
                columns={ default_columns }  
                search={{ searchFormatted: true }}
            >
                {
                    props => (
                        <>
                            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} style={{ width: "300px", float: "right", padding: "10px" }}>
                                <SearchBar  {...props.searchProps} srText="" />
                            </div>

                            <BootstrapTable
                                {...props.baseProps}
                                keyField="id"
                                defaultSorted={defaultSorted}
                                expandRow={expandRow}
                                data={instancelist}
                                noDataIndication="No Compute Data Found, Please Select All Input Field From Previous Step."
                                columns={default_columns}
                                selectRow={selectRow}
                                pagination={paginationFactory({ sizePerPage: 5 })}
                            />
                        </>
                    )
                }
            </ToolkitProvider>


        </>
    )

}

export default EleComputeList;