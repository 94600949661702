import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import GetNumOfMonthDays from '../../../../utils/datetime'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'

//Loader
import Spinner from 'react-bootstrap/Spinner';

import EleComputeList from '../elecompute/elecomputelist'

//progressbar
import Progress from '../../../../components/progress.js'
//Count-up
import CountUp from 'react-countup';
import OortCompairsonPricing from '../oortcompairson'

//Auth Route
import { useHistory } from "react-router-dom";
import AuthRoute from '../../../../_helpers/route'

//
//header
// import Header from '../../../../components/partials/dashboard/HeaderStyle/header'
// //subheader
// import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header'
// //sidebar
// import Sidebar from '../../../../components/partials/dashboard/SidebarStyle/sidebar'
// //footer
// import Footer from '../../../../components/partials/dashboard/FooterStyle/footer'
import SettingOffcanvas from '../../../../components/partials/components/settingoffcanvas'
// import BtnCldElements from '../btncldelements'

import EmitterContext from '../../../../context/Emitter'
import PriceEmitterContext from '../../../../context/PriceEmitter'


// import ListGroup from 'react-bootstrap/ListGroup';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
// eslint-disable-next-line
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

//jquerry params
import { param } from 'jquery'

//Api
import api from '../../../../api/api';

//Alerts
// import Alerts from '../../../../components/alert'
import AlertDismissible from '../../../../utils/alertdismissible'

const ComputeOpt = (props) => {
    const [show, AccountShow] = useState('A');
    const history = useHistory();

    const onClickOortDashboard = () => {
        history.push(AuthRoute.OORT_DASHBOARD)
    }

    const [cloud, setCloud] = useState([]);
    const [cloudId, setCloudId] = useState('');
    const [instanceType, setinstanceType] = useState([]);
    const [region, setRegion] = useState([]);
    const [coreCpu, setCoreCpu] = useState([])
    const [coreMemory, setCoreMemory] = useState([])
    const [userSelectedCloudName, setUserSelectedCloudName] = useState("")
    const [userSelectedInstanceType, setUserSelectedInstanceType] = useState("")

    const [selectedCpu, setSelectedCpu] = useState("")
    const [selectedMemory, setSelectedMemory] = useState("")
    const [selectedRegion, setSelectedRegion] = useState("")

    const [fetchedResourrce, setFetchedResourrce] = useState([])
    const [checkBox, setCheckBox] = useState()

    //compute price
    const [onDemandComputePrice, setOnDemandComputePrice] = useState([])
    const [reservedComputePrice, setReservedComputePrice] = useState([])
    

    const [loading, setLoading] = useState(false);

    const reservedcomputepricelist = reservedComputePrice

    const default_columns = [
        // { dataField: 'id', text: 'Id', sort: true },
        { dataField: 'price_usd', text: 'Price', sort: true },
        { dataField: 'unit', text: 'Unit', sort: true },
        { dataField: 'years', text: 'Years', sort: true },
        { dataField: 'purchaseOption', text: 'Purchase Option', sort: true },

    ];

    const defaultSorted = [{
        dataField: 'price_usd',
        order: 'asc',
    }];

    

    const OnClickGetPrice = (e) => {
        e.preventDefault();
        try {
            setLoading(true); // Set loading before sending API request
            api.GetCloudPrice(
                userSelectedCloudName,
                checkBox.instanceType,
                selectedRegion,
                param(checkBox)
            )
                .then((res) => {
                    if (res.data.length !== 0) {
                        setOnDemandComputePrice(res.data.ondemand)
                        setReservedComputePrice(res.data.reserved_price)
                        setLoading(false)
                    }

                })
        } catch (error) {
            setLoading(false); // Stop loading in case of error
            console.error(error);
        }

    }



    useEffect(() => {
        api.GetCloud()
            .then((req) => {
                setCloud(req.data);
            })
    }, [cloudId])

    const getCpuResponse = (event) => {
        setSelectedCpu(event.target.value)
    }

    const getMemResponse = (event) => {
        setSelectedMemory(event.target.value)
    }

    const getRegion = (event) => {
        setSelectedRegion(event.target.value)
    }

    const handlecloud = (event) => {
        const getcloudid = event.target.value;
        setCloudId(getcloudid);
    }

    useEffect(() => {
        if (selectedCpu.length !== 0 && selectedMemory.length !== 0 && selectedCpu !== '--select cpu--' && selectedMemory !== '--select memory--' && userSelectedCloudName.length !== 0 && userSelectedInstanceType.length !== 0 && selectedRegion.length !== 0 && userSelectedCloudName !== '--select cloud--' && userSelectedInstanceType !== '--select instance type--' && selectedRegion !== '--select region--') {
            api.GetCloudData(userSelectedCloudName, userSelectedInstanceType, param({ cpu: selectedCpu, memory: selectedMemory, region: selectedRegion }))
                .then((res) => {
                    setFetchedResourrce(res.data);
                })
        }
    }, [selectedCpu, selectedMemory, userSelectedCloudName, userSelectedInstanceType, selectedRegion])

    const getUserResponse = (event) => {
        const cloud_name = cloud[cloudId - 1]["cloud"]
        const instance_type = cloud_name + "_" + event.target.value.split(' ').join('_')
        setUserSelectedCloudName(cloud_name)
        setUserSelectedInstanceType(instance_type)
    }

    useEffect(() => {
        //get cpu and memory
        if (userSelectedCloudName.length !== 0 && userSelectedInstanceType.length !== 0) {
            api.GetRoot(userSelectedCloudName, userSelectedInstanceType)
                .then((res) => {
                    setCoreCpu(res.data["CPU (CORE)"]);
                    setCoreMemory(res.data["MEMORY (GB)"]);
                })
        }
    }, [userSelectedCloudName, userSelectedInstanceType])

    useEffect(() => {
        if (cloudId.length !== 0) {
            //instance type
            api.GetInstanceType(cloudId)
                .then((res) => {
                    setinstanceType(res.data);
                })
            //region    
            api.GetRegion(cloudId)
                .then((res) => {
                    setRegion(res.data);
                })
        }

    }, [cloudId]);

    useEffect(() => {
        function OnClickSetHistory() {
            try {
                if (onDemandComputePrice[0] !== '0') {
                    api.PostSearchHistory(
                        {
                            "instance_type": checkBox.instanceType,
                            "ondemand_cost_price": onDemandComputePrice[0]["price_usd"],
                            "cpu": selectedCpu,
                            "memory": selectedMemory
                        }

                    )
                        .then((res) => {
                            if (res.data.length !== 0) {
                            }

                        })
                }
            } catch (error) {
                // console.error(error);
            }
        }
        OnClickSetHistory()
    }, [onDemandComputePrice])

    return (
        <>
            <div className="col-md-12">
                <Row >
                    <Col>
                        <Card>
                            {/* <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Estimate</h4>
                                </div>
                            </Card.Header> */}
                            <Card.Body>
                                <Form id="form-wizard1" className="text-center mt-3">
                                    <ul id="top-tab-list" className="p-0 row list-inline">
                                        <li className={` ${show === 'Image' ? ' active done' : ''} ${show === 'Personal' ? ' active done' : ''} ${show === 'Account' ? ' active done' : ''} ${show === 'A' ? 'active' : ''} col-lg-4 col-md-6 text-start mb-2 active`} id="account">
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                                                    </svg>
                                                </div>
                                                <span>TYPE</span>
                                            </Link>
                                        </li>
                                        <li id="personal" className={`${show === 'Personal' ? ' active done' : ''} ${show === 'Image' ? ' active done' : ''} ${show === 'Account' ? 'active ' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-cloud-plus" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                    </svg>
                                                </div>
                                                <span>RESOURCE</span>
                                            </Link>
                                        </li>
                                        <li id="payment" className={`${show === 'Image' ? ' active done' : ''} ${show === 'Personal' ? 'active' : ''} col-lg-4 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                    </svg>
                                                </div>
                                                <span>PRICE</span>
                                            </Link>
                                        </li>
                                        {/* <li id="confirm" className={`${show === 'Image' ? ' active ' : ''} col-lg-3 col-md-6 mb-2 text-start`}>
                                            <Link to="#">
                                                <div className="iq-icon me-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-sliders2-vertical" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M0 10.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1H3V1.5a.5.5 0 0 0-1 0V10H.5a.5.5 0 0 0-.5.5ZM2.5 12a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2a.5.5 0 0 0-.5-.5Zm3-6.5A.5.5 0 0 0 6 6h1.5v8.5a.5.5 0 0 0 1 0V6H10a.5.5 0 0 0 0-1H6a.5.5 0 0 0-.5.5ZM8 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2A.5.5 0 0 0 8 1Zm3 9.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1H14V1.5a.5.5 0 0 0-1 0V10h-1.5a.5.5 0 0 0-.5.5Zm2.5 1.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2a.5.5 0 0 0-.5-.5Z" />
                                                    </svg>
                                                </div>
                                                <span>COMPARISON</span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                    <fieldset className={`${show === 'A' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Cloud Provider: *</label>
                                                        <select type="text" className="form-select" onChange={(e) => handlecloud(e)}>
                                                            <option>--select cloud--</option>
                                                            {
                                                                cloud.map((getcloud) => (
                                                                    <option key={getcloud.id} value={getcloud.id}> {getcloud.cloud}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Instance Type: *</label>
                                                        <select type="text" className="form-select" onChange={(e) => getUserResponse(e)}>
                                                            <option>--select instance type--</option>
                                                            {
                                                                instanceType.length !== 0 ? instanceType.map((instance, index) => (
                                                                    <option key={index} value={instance}>{instance}</option>
                                                                )) : <Spinner animation="border" variant="primary" />
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Region: *</label>
                                                        <select type="text" className="form-select required" data-live-search="true" onChange={(e) => getRegion(e)}>
                                                            <option>--select region--</option>
                                                            {
                                                                region.map((reg, index) => (
                                                                    <option key={index} value={reg}>{reg}</option>
                                                                ))


                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">CPU Core: *</label>
                                                        <select type="text" className="form-select" onChange={(e) => getCpuResponse(e)}>
                                                            <option>--select cpu--</option>
                                                            {
                                                                coreCpu.map((reg, index) => (
                                                                    <option key={index} value={reg}>{reg}</option>
                                                                ))


                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Memory (GB): *</label>
                                                        <select type="text" className="form-select" onChange={(e) => getMemResponse(e)}>
                                                            <option>--select memory--</option>
                                                            {
                                                                coreMemory.map((reg, index) => (
                                                                    <option key={index} value={reg}>{reg}</option>
                                                                ))


                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="mb-4">Step 1 of 4 </p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <button type="button" name="next" className="btn btn-primary next action-button float-end" value="Next" onClick={() => AccountShow('Account')} >Next</button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Account' ? 'd-block' : 'd-none'}`}>

                                                <EmitterContext.Provider value={fetchedResourrce}>
                                                    <PriceEmitterContext.Provider value={{checkBox, setCheckBox}}>
                                                            <EleComputeList />
                                                    </PriceEmitterContext.Provider>
                                                </EmitterContext.Provider>

                                        <button type="button" name="next" className="btn btn-primary next action-button float-end" value="Next" onClick={(e) => { AccountShow('Personal'); OnClickGetPrice(e); }} >Next</button>
                                        <button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('A')} >Previous</button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Personal' ? 'd-block' : 'd-none'}`}>
                                        <div className="form-card text-start">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h5 className="mb-4">Price Type (Step 3 of 4)</h5>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <Col lg="12">
                                                    <Card>
                                                        <Card.Body>
                                                            {

                                                                loading !== false ? <Spinner animation="border" variant="primary" /> : onDemandComputePrice[0] !== '0' ? onDemandComputePrice.map(ondemandprice =>
                                                                    <h2 className="counter">$
                                                                        <CountUp start={1000} key={ondemandprice.index} end={ondemandprice.price_usd} decimals={4} duration={3} />
                                                                    </h2>) : <AlertDismissible />
                                                            }

                                                            {/* "unit":"Hrs" */}

                                                            <p className="mb-2">Hourly (Rounded to four decimals)</p>
                                                            {/* <h6>Pricing</h6> */}
                                                            <Link to="#" className="mt-4 btn btn-primary d-block rounded">On Demanad</Link>
                                                            <div className="mt-3">
                                                                <div className="pb-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <p className="mb-0">Daily</p>
                                                                        {onDemandComputePrice.map(ondemandprice => <h4 key={ondemandprice.index}>$ {(ondemandprice.price_usd * 24).toFixed(4)}</h4>)}
                                                                    </div>
                                                                    {onDemandComputePrice.map(ondemandprice => <Progress softcolors="info" key={ondemandprice.index} color="info" className="shadow-none w-100" value={ondemandprice.price_usd * 24} minvalue={0} maxvalue={100} style={{ height: "10px" }} />)}
                                                                </div>
                                                                <div className="pb-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <p className="mb-0">Monthly</p>
                                                                        {onDemandComputePrice.map(ondemandprice => <h4 key={ondemandprice.index}>$ {(ondemandprice.price_usd * 24 * GetNumOfMonthDays()).toFixed(4)}</h4>)}
                                                                    </div>
                                                                    {onDemandComputePrice.map(ondemandprice => <Progress softcolors="success" color="success" key={ondemandprice.index} className="shadow-none w-100" value={ondemandprice.price_usd * 24 * GetNumOfMonthDays()} minvalue={0} maxvalue={100} style={{ height: "10px" }} />)}
                                                                </div>
                                                                <div className="pb-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <p className="mb-0">Yearly</p>
                                                                        {onDemandComputePrice.map(ondemandprice => <h4 key={ondemandprice.index}>$ {(ondemandprice.price_usd * 24 * GetNumOfMonthDays() * 12).toFixed(4)}</h4>)}
                                                                    </div>
                                                                    {onDemandComputePrice.map(ondemandprice => <Progress softcolors="primary" key={ondemandprice.index} color="primary" className="shadow-none w-100" value={ondemandprice.price_usd * 24 * GetNumOfMonthDays() * 12} minvalue={0} maxvalue={100} style={{ height: "10px" }} />)}
                                                                </div>
                                                                <div>
                                                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                                                        <p className="mb-0">3 Years</p>
                                                                        {onDemandComputePrice.map(ondemandprice => <h4 key={ondemandprice.index}>$ {(ondemandprice.price_usd * 24 * GetNumOfMonthDays() * 12 * 3).toFixed(4)}</h4>)}
                                                                    </div>
                                                                    {onDemandComputePrice.map(ondemandprice => <Progress softcolors="success" color="success" className="shadow-none w-100" value={(ondemandprice.price_usd * 24 * GetNumOfMonthDays() * 12 * 3).toFixed(4)} minvalue={0} maxvalue={100} style={{ height: "10px" }} />)}
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg="12">
                                                    <Card>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <Link to="#" className="mt-4 btn btn-success d-block rounded">Reserved</Link>
                                                            </div>
                                                        </div>
                                                        <Card.Body>
                                                            <ToolkitProvider
                                                                bootstap4
                                                                keyField="id"
                                                                data={reservedcomputepricelist}
                                                                columns={default_columns}

                                                            >
                                                                {
                                                                    props => (
                                                                        <>


                                                                            <BootstrapTable
                                                                                {...props.baseProps}
                                                                                keyField="id"
                                                                                defaultSorted={defaultSorted}
                                                                                data={reservedcomputepricelist}
                                                                                striped bordered hover
                                                                                noDataIndication="Reserved Price Not Available for this Selection"
                                                                                pagination={paginationFactory({ sizePerPage: 5 })}
                                                                                columns={default_columns}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Card.Body>


                                                    </Card>
                                                </Col>
                                            </div>
                                        </div>
                                        {/* <button type="button" name="next" className="btn btn-primary next action-button float-end" value="Submit" onClick={() => AccountShow('Image')} >Compare</button> */}
                                        <button type="button" name="previous" className="btn btn-dark previous action-button-previous float-end me-1" value="Previous" onClick={() => AccountShow('Account')} >Previous</button>
                                    </fieldset>
                                    <fieldset className={`${show === 'Image' ? 'd-block' : 'd-none'}`}>

                                        <div>
                                            <OortCompairsonPricing />
                                        </div>





                                        <button type="button" name="previous" className="btn float-end me-1" onClick={onClickOortDashboard} >Main Menu</button>
                                    </fieldset>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>


            <SettingOffcanvas />
        </>
    )
}

export default ComputeOpt;
