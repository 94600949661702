import axios from "axios";
// eslint-disable-next-line
import AuthRoute from "../_helpers/route";
import { oortLogin } from "./auth";

const handleGoogleAuth = async () => {
  // auth/google/authorize function
  const gettingTokken = async () => {

    await axios({
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT + "/auth/google/authorize",
    }).then((response) => {

      const url = response.data.authorization_url;

      window.open(url, "_self");
    });
  };

  //Calling auth/google/authorize
  await gettingTokken();
};

// calling auth/google/callback
const signwithgoogle = async (state, code) => {
  const URL =
    process.env.REACT_APP_API_ENDPOINT +
    "/auth/google/callback?code=" +
    code +
    "&state=" +
    state;

  let headersList = {
    Accept: "*/*",
  };

  await axios({
    method: "get",
    url: URL,
    header: headersList,
  }).then((response) => {
    const access_token = response.data.access_token;
    if (access_token !== null) {
      sessionStorage.setItem("oort-token", access_token);
      window.open(oortLogin(), "_self");
    }
  });
};

export { handleGoogleAuth, signwithgoogle };