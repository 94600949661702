import React from "react";
import { toast } from 'react-toastify';


export default function Api_Toast(message) {
    const toast_message = toast(message)
    return (
        toast_message
    )
}
