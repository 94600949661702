import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//Loader
import Spinner from 'react-bootstrap/Spinner';

//Auth Route
import { useHistory } from "react-router-dom";
import AuthRoute from './route'

function Global_Spinner_Modal() {
    const [shown, setShow] = useState(true);
    return (
        <>
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Spinner animation="border" variant="primary" />
            </div>
        </>
    );
}

export default Global_Spinner_Modal;