import React, {useEffect} from 'react'
import { Row, Col, Image, Form, Button, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import AuthRoute from '../../../_helpers/route'
import { useLocation } from "react-router-dom";



// img
// import facebook from '../../../assets/images/brands/fb.svg'
import google from '../../../assets/images/brands/gm.svg'
// import instagram from '../../../assets/images/brands/im.svg'
// import linkedin from '../../../assets/images/brands/li.svg'
import mvlogosmall from '../../../assets/images/mvbrands/mvlogosmall.svg'
import auth5 from '../../../assets/images/auth/05.png'
import axios from 'axios'

// oauth
import { handleGoogleAuth, signwithgoogle } from '../../../utils/handleGoogleAuth'

const SignUp = () => {
   const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
   } = useForm();

   // oauth
   function useQuery() {
      const { search } = useLocation();
  
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();

   useEffect(() => {

      let state = query.get("state");
      let code = query.get("code");
  
      if (state && code !== null) {
        signwithgoogle(state, code);
      }
    },[]);

   const onSubmit = (data) => {
      axios(process.env.REACT_APP_API_ENDPOINT + AuthRoute.APP_REGISTER, {
         method: 'POST',
         data: (data),
         headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
         }
      })
         .then(function (response) {
            //handle success

            if (JSON.stringify(response.data.id).length > 0 && JSON.stringify(response.data.is_active) === "true") {
               // <Alert variant="success"><Alert.Heading>Signup Successful!
               // </Alert.Heading></Alert>
               alert("Signup Successful! Please Login");
               window.location = "/"
            }
         })
         .catch(function (error) {
            if (error.response.data.detail === "REGISTER_USER_ALREADY_EXISTS") {
               alert("User Already Exists, Please Login!")
               //window.location = "/"
            }
            if (error.response.status === 422) {
               alert("Something is Missing, Please Check and Try Again")
               window.location = "/auth/sign-up"
            }

         });
   }
   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">

                  <Image src={auth5} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </div>
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                           <Card.Body>
                              <Link to="/" className="navbar-brand d-flex align-items-center mb-3">
                                 <Image src={mvlogosmall} width="50" className="text-primary" viewBox="0 0 30 30" fill="none" alt="images" />
                                 {/* <h2 className="mb-2 text-center"></h2> */}
                              </Link>
                              <h1 className="mb-2 tex-left">MetricsViews</h1>
                              <br></br>
                              <h5 className="mb-2 tex-left">Sign Up</h5>
                              {/* <p className="text-center">Create your Hope UI account.</p> */}
                              <br></br>
                              <Form onSubmit={handleSubmit(onSubmit)} >
                                 <Row>
                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="full-name" className="">First Name</Form.Label>
                                          <Form.Control type="text" className="" id="first_name" {...register('first_name', { required: "First Name is required." }, { minLength: 3 })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="first_name" /></span>
                                       </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="full-name" className="">Last Name</Form.Label>
                                          <Form.Control type="text" className="" id="last_name" {...register('last_name', { required: " Name is required." }, { minLength: 3 })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="last_name" /></span>
                                       </Form.Group>
                                    </Col>
                                       <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="full-name" className="">Company Name</Form.Label>
                                          <Form.Control type="text" className="" id="full-name" {...register('company_name', { required: "Company Name is required." }, { minLength: 3 })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="company_name" /></span>
                                       </Form.Group>
                                    </Col>

                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="email" className="">Email</Form.Label>
                                          <Form.Control type="email" className="" id="email" {...register("email", { required: "Email Address is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" } })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="email" /></span>
                                       </Form.Group>
                                    </Col>

                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="password" className="">Password</Form.Label>
                                          <Form.Control type="password" className="" id="password" {...register("password", { required: "Enter your password", minLength: { value: 8, message: "Password must have at least 8 characters" } })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="password" /></span>
                                       </Form.Group>
                                    </Col>
                                    <Col lg="6">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="confirm-password" className="">Confirm Password</Form.Label>
                                          <Form.Control type="password" className="" id="confirm-password" {...register("confirm-password", { validate: (value) => { const { password } = getValues(); return password === value || "Passwords should match!"; } })} placeholder=" " />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="confirm-password" /></span>
                                       </Form.Group>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-center">
                                       <Form.Check className="mb-3 form-check">
                                          <Form.Check.Input type="checkbox"  {...register("checkbox", { required: "Please accept terms & conditions", pattern: { value: true } })} id="customCheck1" />
                                          <Form.Check.Label htmlFor="customCheck1">I agree with the terms of use</Form.Check.Label>
                                          <br />
                                          <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="checkbox" /></span>
                                       </Form.Check>
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <Button type="submit" variant="primary">Sign Up</Button>
                                 </div>


                                 <p className="text-center my-3">or sign in with other accounts?</p>
                                 <div className="d-flex justify-content-center">
                                       <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                          {/* <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                             <Link to="#"><Image src={facebook} alt="fb" /></Link>
                                          </ListGroup.Item> */}
                                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                             <Link to="#"><Image onClick={handleGoogleAuth} src={google} alt="gm" /></Link>
                                          </ListGroup.Item>
                                          {/* <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                             <Link to="#"><Image src={instagram} alt="im" /></Link>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" className="list-group-item border-0 pb-0">
                                             <Link to="#"><Image src={linkedin} alt="li" /></Link>
                                          </ListGroup.Item> */}
                                       </ListGroup>
                                    </div>
                                 <p className="mt-3 text-center">
                                    Already have an Account <Link to="/" className="text-underline">Sign In</Link>
                                 </p>
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <div className="sign-bg sign-bg-right">
                     <svg width="280" height="230" viewBox="0 0 421 359" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-15.0845" y="154.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -15.0845 154.773)" fill="#3A57E8" />
                           <rect x="149.47" y="319.328" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 149.47 319.328)" fill="#3A57E8" />
                           <rect x="203.936" y="99.543" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 203.936 99.543)" fill="#3A57E8" />
                           <rect x="204.316" y="-229.172" width="543" height="77.5714" rx="38.7857" transform="rotate(45 204.316 -229.172)" fill="#3A57E8" />
                        </g>
                     </svg>
                  </div>
               </Col>
            </Row>
         </section>
      </>
   )
}
export default SignUp