import React, { useState, useEffect } from 'react'
// import FsLightbox from 'fslightbox-react';
import api from '../../../api/api';

import { Row, Col, Image, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'

//progressbar
import Progress from '../../../components/progress.js'

// img
import shap1 from '../../../assets/images/shapes/01.png'
import shap2 from '../../../assets/images/shapes/02.png'
import shap3 from '../../../assets/images/shapes/03.png'
import shap4 from '../../../assets/images/shapes/04.png'
import shap5 from '../../../assets/images/shapes/05.png'
import shap6 from '../../../assets/images/shapes/06.png'


// import mvlogosmall from '../../../assets/images/mvbrands/mvlogosmall.svg'

import { Link } from 'react-router-dom'
// img 

import avatars11 from '../../../assets/images/avatars/01.png'
import avatars22 from '../../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../../assets/images/avatars/avtar_5.png'


//header
import Header from '../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../components/partials/dashboard/FooterStyle/footer'

//setting of canvas 
import SettingOffcanvas from '../../../components/partials/components/settingoffcanvas'
//capitalize
import { CapitalizeFirstLetter } from '../../../utils/capitalize';
//use history 
import { useHistory } from "react-router-dom";
// eslint-disable-next-line
import AuthRoute from '../../../_helpers/route';

const UserEstimatorLog = () => {

    const [userProfile, setuserProfile] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [error, setError] = useState();
    // eslint-disable-next-line
    const history = useHistory()

    useEffect(() => {

        const getUser = () => {
            try {
                api.UserprofileGet()
                    .then(response => {
                        if (response.data.length !== 0) {
                            setuserProfile(response.data)
                            setLoading(false);
                            setError(null)
                        }
                    })
            } catch (err) {
                setError(err);
                setuserProfile(null);
            }
            finally {
                setLoading(false);
            }
        }
        getUser()


    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    if (userProfile.first_name !== undefined) {
        sessionStorage.setItem('first_name', CapitalizeFirstLetter(userProfile.first_name))
        sessionStorage.setItem('last_name', CapitalizeFirstLetter(userProfile.last_name))
        sessionStorage.setItem('company_name', CapitalizeFirstLetter(userProfile.company_name))
        sessionStorage.setItem('email', userProfile.email)
        sessionStorage.setItem('company_id', userProfile.company_id)
        sessionStorage.setItem('id', userProfile.id)
    }


    return (
        <>
            <Loader />
            <Sidebar />
            <main className="main-content">

                < Header />
                <SubHeader />
                <div className="py-0 conatiner-fluid content-inner mt-n5">

                    <Tab.Container defaultActiveKey="first">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                                            <div className="d-flex flex-wrap align-items-center">
                                                <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                                    <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                                    <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100" src={avatars22} alt="profile-pic" />
                                                    <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100" src={avatars33} alt="profile-pic" />
                                                    <Image className="theme-color-green-img img-fluid rounded-pill avatar-100" src={avatars55} alt="profile-pic" />
                                                    <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100" src={avatars66} alt="profile-pic" />
                                                    <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100" src={avatars44} alt="profile-pic" />
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                    {/* <h6 className="mb-0 caption-title">{sessionStorage.getItem('first_name')}</h6> */}

                                                    <h4 className="mb-0 caption-sub-title">{sessionStorage.getItem('first_name')} </h4>
                                                    <h4 className="me-2 h4">{"\u00a0"}{sessionStorage.getItem('last_name')}</h4>
                                                    <h3 className="me-2 h4"> - {sessionStorage.getItem('company_name')}</h3>

                                                </div>
                                            </div>
                                            <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                                {/* <Nav.Item as="li">
                                    <Nav.Link eventKey="first">Feed</Nav.Link>
                                 </Nav.Item> */}
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="second">Activity</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item as="li">
                                    <Nav.Link eventKey="third">Friends</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="fourth">Profile</Nav.Link>
                                 </Nav.Item> */}
                                            </Nav>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">User Log - Estimator </h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <div className="table-responsive mt-4">
                                            <table id="basic-table" className="table table-striped mb-0" role="grid">
                                                <thead>
                                                    <tr>
                                                        <th>Companies</th>
                                                        <th>Members</th>
                                                        <th>Budget</th>
                                                        <th>Status</th>
                                                        <th>Completion</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <Image className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap1} alt="profile" />
                                                                <h6>Soft UI XD Version</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">MM</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>$14000</td>
                                                        <td><div className="text-info">Pending</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>60%</h6>
                                                            </div>
                                                            <Progress softcolors="info" color="info" className="shadow-none w-100" value={60} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap2} alt="profile" />
                                                                <h6>Add Progress Track</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>$3000</td>
                                                        <td><div className="text-danger">Pending</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>10%</h6>
                                                            </div>
                                                            <Progress softcolors="danger" color="danger" className="shadow-none w-100" value={10} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap3} alt="profile" />
                                                                <h6>Fix Platform Errors</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>Not set</td>
                                                        <td><div className="text-success">Completed</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>100%</h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={10} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap4} alt="profile" />
                                                                <h6>Launch Our Mobile App</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">AP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">DP</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>$20500</td>
                                                        <td><div className="text-success">Completed</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>100%</h6>
                                                            </div>
                                                            <Progress softcolors="success" color="success" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap5} alt="profile" />
                                                                <h6>Add the New Pricing Page</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>$500</td>
                                                        <td><div className="text-primary">On Schedule</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>25%</h6>
                                                            </div>
                                                            <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={25} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                                                                    src={shap6} alt="profile" />
                                                                <h6>Redesign New Online Shop</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="iq-media-group iq-media-group-1">
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                </Link>
                                                                <Link to="#" className="iq-media-1">
                                                                    <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>$2000</td>
                                                        <td><div className="text-warning">Completed</div></td>
                                                        <td>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <h6>40%</h6>
                                                            </div>
                                                            <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={40} minvalue={0} maxvalue={100} style={{ height: "6px" }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                <Footer />
            </main>
            <SettingOffcanvas />
        </>
    )

}

export default UserEstimatorLog;