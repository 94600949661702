import React, { useEffect, useState } from 'react'
import { ToastContainer } from "react-toastify";
import Api_Toast from '../../../_helpers/api_toast';
import { Row, Col, Image, Form, Button, ListGroup, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import AuthRoute from '../../../_helpers/route'
import {service} from '../../../utils/auth'
import { setJwtToken, oortLogin } from '../../../utils/auth'
import { useHistory, useLocation } from "react-router-dom";

// img
// import facebook from '../../../assets/images/brands/fb.svg'
import google from '../../../assets/images/brands/gm.svg'
// import instagram from '../../../assets/images/brands/im.svg'
// import linkedin from '../../../assets/images/brands/li.svg'
import auth1 from '../../../assets/images/auth/01.png'
import mvlogosmall from '../../../assets/images/mvbrands/mvlogosmall.svg'
import { param } from 'jquery'

// oauth
import { handleGoogleAuth, signwithgoogle } from '../../../utils/handleGoogleAuth'
//Loader
import Spinner from 'react-bootstrap/Spinner';
import Global_Spinner_Modal from '../../../_helpers/globalspinnermodal';



const SignIn = () => {
   const history = useHistory();
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm();

   // oauth
   function useQuery() {
      const { search } = useLocation();
  
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();

   const [Loading, setLoading] = useState(false)

   // eslint-disable-next-line
   useEffect(() => {
      let state = query.get("state");
      let code = query.get("code");
      if (state && code !== null) {
         setLoading(true)
         signwithgoogle(state, code)

      }
   }, []);
  
   const onSubmit = async (data) => {

      await service.post(AuthRoute.APP_LOGIN, param(data))
         .then(response => {
            setJwtToken(response.data.access_token)
            history.push(oortLogin())
         }).catch(
            function (error) {
               if (error.response.data.detail === "LOGIN_BAD_CREDENTIALS") {
                  Api_Toast("Oops, You entered wrong login credentials")

               }
               else {
                  Api_Toast("Oops, Authentication Failed! ")
                  // return Promise.reject(error)
               }
            }
         )
   }
   return (
      <>
         {Loading === true ? <Global_Spinner_Modal /> :
            <section className="login-content">
               <Row className="m-0 align-items-center bg-white vh-100">
                  <Col md="6">
                     <Row className="justify-content-center">
                        <Col md="10">
                           <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                              <Card.Body>
                                 <Link to="/" className="navbar-brand d-flex align-items-center mb-3">
                                    <Image src={mvlogosmall} width="50" className="text-primary" viewBox="0 0 30 30" fill="none" alt="images" />
                                    <h2 className="mb-2 text-center">MetricsViews</h2>
                                 </Link>
                                 {/* <h2 className="mb-2 text-center">Sign In</h2> */}
                                 <h4><p className="text-center">Sign In to stay connected!</p></h4>
                                 <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                       <Col lg="12">
                                          <Form.Group className="form-group">
                                             <Form.Label htmlFor="email" className="">Email</Form.Label>
                                             <Form.Control type="email" className="" id="email" {...register("username", { required: "Email Address is required", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" } })} placeholder=" " />
                                             <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="username" /></span>
                                          </Form.Group>
                                       </Col>
                                       <Col lg="12" className="">
                                          <Form.Group className="form-group">
                                             <Form.Label htmlFor="password" className="">Password</Form.Label>
                                             <Form.Control type="password" className="" id="password" {...register("password", { required: "Enter your password", minLength: { value: 8, message: "Password must have at least 8 characters" } })} placeholder=" " />
                                             <span style={{ color: '#bf1650' }}><ErrorMessage errors={errors} name="password" /></span>
                                          </Form.Group>
                                       </Col>
                                       <Col lg="12" className="d-flex justify-content-between">
                                          <Form.Check className="form-check mb-3">
                                             <Form.Check.Input type="checkbox" id="customCheck1" />
                                             <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                                          </Form.Check>
                                          {/* <Link to="/auth/recoverpw">Forgot Password?</Link> */}
                                       </Col>
                                    </Row>
                                    <div className="d-flex justify-content-center">
                                       <Button type="submit" variant="btn btn-primary">Sign In</Button>
                                    </div>
                                    <p className="text-center my-3">or sign in with other accounts?</p>
                                    <div className="d-flex justify-content-center">
                                       <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                          {/* <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={facebook} alt="fb" /></Link>
                                       </ListGroup.Item> */}
                                          <ListGroup.Item as="li" className="border-0 pb-0">
                                             <Link to="#"><Image onClick={handleGoogleAuth} src={google} alt="gm" /></Link>
                                          </ListGroup.Item>
                                          {/* <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={instagram} alt="im" /></Link>
                                       </ListGroup.Item>
                                       <ListGroup.Item as="li" className="border-0 pb-0">
                                          <Link to="#"><Image src={linkedin} alt="li" /></Link>
                                       </ListGroup.Item> */}
                                       </ListGroup>
                                    </div>
                                    <p className="mt-3 text-center">
                                       Don’t have an account? <Link to="auth/sign-up" className="text-underline">Click here to sign up.</Link>
                                    </p>
                                 </Form>
                                 <ToastContainer />
                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                     <div className="sign-bg">
                        <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g opacity="0.05">
                              <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                              <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                              <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                              <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                           </g>
                        </svg>
                     </div>
                  </Col>
                  <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                     {/* <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" /> */}
                     <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
                  </Col>
               </Row>

            </section>
         }
      </>
   )
}
export default SignIn

