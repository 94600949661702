import React, { useEffect } from 'react'
import { bindActionCreators } from "redux"
import SettingOffcanvas from '../../components/partials/components/settingoffcanvas'
import Loader from '../../components/Loader'
// import { CapitalizeFirstLetter } from '../../../utils/capitalize'
// import { useHistory } from "react-router-dom"; 




// import OortHomeRouter from '../../../router/OortHome-router'
// import SimpleRouter from '../../../router/simple-router'

// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../store/setting/setting'
import { connect } from "react-redux"

//header
import Header from '../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer'
// import { Accordion } from 'react-bootstrap'
//default 
//import DefaultRouter from '../../../router/default-router'


//img
import shapes1 from '../../assets/images/shapes/01.png'
import shapes2 from '../../assets/images/shapes/02.png'
import shapes3 from '../../assets/images/shapes/03.png'
import shapes4 from '../../assets/images/shapes/04.png'
import shapes5 from '../../assets/images/shapes/05.png'


//progressbar
import Progress from '../../components/partials/components/progress'
import { Link } from 'react-router-dom'


import { Row, Col, Dropdown, Button, } from 'react-bootstrap'
//apexcharts
import Chart from "react-apexcharts";

import BtnMVDashboard from './btnmvdashboard'






const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),

    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})




const MVDashboard = (props) => {
    useEffect(() => {
        //   darkmode
        const colorMode = sessionStorage.getItem('color-mode');
        if (colorMode === null) {
            props.ModeAction(props.darkMode);
        }
        else {
            props.ModeAction(colorMode);
        }

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        // rtlmode
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.SchemeDirAction(props.schemeDirMode)
        }
        else {
            props.SchemeDirAction(rtlMode);
        }

        // if (userProfile.first_name !== undefined && userProfile.last_name !== undefined && userProfile.company_name !== undefined && userProfile.first_name !== null && userProfile.last_name !== null && userProfile.company_name !== null) {
        //     sessionStorage.setItem('company_name', CapitalizeFirstLetter(userProfile.company_name))
        // }





    },[props])

    const chart1 = {
        options: {
            chart: {
                fontFamily: '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: false,
                }
            },
            colors: [props.colorprimarymode, props.cololrinfomode],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                    minWidth: 19,
                    maxWidth: 19,
                    style: {
                        colors: "#8A92A6",
                    },
                    offsetX: -5,
                },
            },
            legend: {
                show: false,
            },
            xaxis: {
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                    show: true,
                    style: {
                        colors: "#8A92A6",
                    },
                },
                lines: {
                    show: false  //or just here to disable only x axis grids
                },
                categories: ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug"]
            },
            grid: {
                show: false,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: .4,
                    opacityTo: .1,
                    stops: [0, 50, 80],
                    colors: [props.colorprimarymode, props.cololrinfomode]
                }
            },
            tooltip: {
                enabled: true,
            },
        },
        series: [{
            name: 'total',
            data: [94, 80, 94, 80, 94, 80, 94]
        }, {
            name: 'pipline',
            data: [72, 60, 84, 60, 74, 60, 78]
        }]


    }

    //chart2
    const chart2 = {
        options: {
            colors: [props.colorprimarymode, props.cololrinfomode],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 10,
                        size: "50%",
                    },
                    track: {
                        margin: 10,
                        strokeWidth: '50%',
                    },
                    dataLabels: {
                        show: false,
                    }
                }
            },
            labels: ['Apples', 'Oranges'],
        },
        series: [55, 75],
    }
    const chart3 = {
        options: {
            chart: {
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            colors: [props.colorprimarymode, props.cololrinfomode],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '28%',
                    endingShape: 'rounded',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['S', 'M', 'T', 'W', 'T', 'F', 'S', 'M', 'T', 'W'],
                labels: {
                    minHeight: 20,
                    maxHeight: 20,
                    style: {
                        colors: "#8A92A6",
                    },
                }
            },
            yaxis: {
                title: {
                    text: ''
                },
                labels: {
                    minWidth: 19,
                    maxWidth: 19,
                    style: {
                        colors: "#8A92A6",
                    },
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands"
                    }
                }
            }
        },
        series: [{
            name: 'Successful deals',
            data: [30, 50, 35, 60, 40, 60, 60, 30, 50, 35,]
        }, {
            name: 'Failed deals',
            data: [40, 50, 55, 50, 30, 80, 30, 40, 50, 55]
        }]


    }




    return (
        <>
            <Loader />
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    < Header />
                    <SubHeader />
                    <div className="py-0 conatiner-fluid content-inner mt-n5" >
                        <BtnMVDashboard />



                        <Col >



                            <Row>
                                <Col md="12" lg="6">
                                    <div className="card" data-aos="fade-up" data-aos-delay="800">
                                        <div className="flex-wrap card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">$855.8K</h4>
                                                <p className="mb-0">Gross Sales</p>
                                            </div>
                                            <div className="d-flex align-items-center align-self-center">
                                                <div className="d-flex align-items-center text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-2">
                                                        <span className="text-secondary">Sales</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center ms-3 text-info">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-2">
                                                        <span className="text-secondary">Cost</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle as={Button} href="#" variant=" text-secondary dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    This Week
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                                    <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="card-body">
                                            <Chart options={chart1.options} series={chart1.series} type="area" height="245" />
                                        </div>
                                    </div>
                                    <div className="card" data-aos="fade-up" data-aos-delay="500">
                                        <div className="text-center card-body d-flex justify-content-around">
                                            <div>
                                                <h2 className="mb-2">750<small>K</small></h2>
                                                <p className="mb-0 text-secondary">Website Visitors</p>
                                            </div>
                                            <hr className="hr-vertial" />
                                            <div>
                                                <h2 className="mb-2">7,500</h2>
                                                <p className="mb-0 text-secondary">New Customers</p>
                                            </div>
                                        </div>
                                    </div> 


                                </Col>
                                <Col md="12" lg="6">
                                    <div className="card" data-aos="fade-up" data-aos-delay="800">
                                        <div className="flex-wrap card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">$855.8K</h4>
                                                <p className="mb-0">Gross Sales</p>
                                            </div>
                                            <div className="d-flex align-items-center align-self-center">
                                                <div className="d-flex align-items-center text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-2">
                                                        <span className="text-secondary">Sales</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center ms-3 text-info">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="currentColor">
                                                        <g>
                                                            <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                                        </g>
                                                    </svg>
                                                    <div className="ms-2">
                                                        <span className="text-secondary">Cost</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle as={Button} href="#" variant=" text-secondary dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    This Week
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                                    <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="card-body">
                                            <Chart options={chart1.options} series={chart1.series} type="area" height="245" />
                                        </div>
                                    </div>
                                    <div className="card" data-aos="fade-up" data-aos-delay="500">
                                        <div className="text-center card-body d-flex justify-content-around">
                                            <div>
                                                <h2 className="mb-2">750<small>K</small></h2>
                                                <p className="mb-0 text-secondary">Website Visitors</p>
                                            </div>
                                            <hr className="hr-vertial" />
                                            <div>
                                                <h2 className="mb-2">7,500</h2>
                                                <p className="mb-0 text-secondary">New Customers</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="12" xl="6">
                                    <div className="card" data-aos="fade-up" data-aos-delay="900">
                                        <div className="flex-wrap card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Earnings</h4>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle as={Button} href="#" variant=" text-secondary" id="dropdownMenuButton1" aria-expanded="false">
                                                    This Week
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                    <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="card-body">
                                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                                                <Chart className="col-md-8 col-lg-8" options={chart2.options} series={chart2.series} type="radialBar" height="250" />
                                                <div className="d-grid gap col-md-4 col-lg-4">
                                                    <div className="d-flex align-items-start">
                                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#3a57e8">
                                                            <g>
                                                                <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
                                                            </g>
                                                        </svg>
                                                        <div className="ms-3">
                                                            <span className="text-secondary">Fashion</span>
                                                            <h6>251K</h6>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-start">
                                                        <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="#4bc7d2">
                                                            <g>
                                                                <circle cx="12" cy="12" r="8" fill="#4bc7d2"></circle>
                                                            </g>
                                                        </svg>
                                                        <div className="ms-3">
                                                            <span className="text-secondary">Accessories</span>
                                                            <h6>176K</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="12" xl="6">
                                    <div className="card" data-aos="fade-up" data-aos-delay="1000">
                                        <div className="flex-wrap card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Conversions</h4>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle as={Button} href="#" variant=" text-secondary" id="dropdownMenuButton3" aria-expanded="false">
                                                    This Week
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                                                    <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>
                                                    <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="card-body">
                                            <Chart className="d-activity" options={chart3.options} series={chart3.series} type="bar" height="230" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="12" lg="12">
                                    <div className="overflow-hidden card" data-aos="fade-up" data-aos-delay="600">
                                        <div className="flex-wrap card-header d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="mb-2 card-title">Enterprise Clients</h4>
                                                <p className="mb-0">
                                                    <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                                                        <path fill="#3a57e8" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                                    </svg>
                                                    15 new acquired this month
                                                </p>
                                            </div>
                                        </div>
                                        <div className="p-0 card-body">
                                            <div className="mt-4 table-responsive">
                                                <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                                    <thead>
                                                        <tr>
                                                            <th>COMPANIES</th>
                                                            <th>CONTACTS</th>
                                                            <th>ORDER</th>
                                                            <th>COMPLETION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes1} alt="profile" />
                                                                    <h6>Addidis Sportwear</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">MM</div>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>$14,000</td>
                                                            <td>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <h6>60%</h6>
                                                                </div>
                                                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={60} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes5} alt="profile" />
                                                                    <h6>Netflixer Platforms</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>$30,000</td>
                                                            <td>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <h6>25%</h6>
                                                                </div>
                                                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={25} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes2} alt="profile" />
                                                                    <h6>Shopifi Stores</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">TP</div>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>$8,500</td>
                                                            <td>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <h6>100%</h6>
                                                                </div>
                                                                <Progress softcolors="success" color="success" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes3} alt="profile" />
                                                                    <h6>Bootstrap Technologies</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">MM</div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">TP</div>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>$20,500</td>
                                                            <td>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <h6>100%</h6>
                                                                </div>
                                                                <Progress softcolors="success" color="success" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded bg-soft-primary img-fluid avatar-40 me-3" src={shapes4} alt="profile" />
                                                                    <h6>Community First</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="iq-media-group iq-media-group-1">
                                                                    <Link to="#" className="iq-media-1">
                                                                        <div className="icon iq-icon-box-3 rounded-pill">MM</div>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td>$9,800</td>
                                                            <td>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <h6>75%</h6>
                                                                </div>
                                                                <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={75} minvalue={0} maxvalue={100} style={{ height: "4px" }} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </div>






                </div>
                <Row>

                </Row>

                <Footer />
            </main>
            <SettingOffcanvas />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MVDashboard)
