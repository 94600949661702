import axios from "axios";
import AuthRoute from "../_helpers/route";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";


// Short duration JWT token (5-10 min)
export function getJwtToken() {
    return sessionStorage.getItem("oort-token")
}

export function setJwtToken(token) {
    sessionStorage.setItem("oort-token", token)
}


export function oortLogin() {
    const jwtToken = getJwtToken();
    const decoded = jwt_decode(jwtToken);
    if (!jwtToken) {
        return AuthRoute.OORT_LOGIN
    } else {
        if(decoded.user_id.length > 0 && decoded.aud[0]==="metricsviews:auth") {
            return AuthRoute.OORT_DASHBOARD
        } else {
            return AuthRoute.OORT_LOGIN
        }   
    }
}

export function ProtectedRoute({ component: Component, ...restOfProps }) {
    var jwtToken = getJwtToken();
    try {
        var decoded = jwt_decode(jwtToken);
        let currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
            return (
                <Route
                    {...restOfProps}
                    render={(props) =>
                        <Redirect to={AuthRoute.OORT_LOGIN} />
                    }
                />
            );
        }
    } catch (error) {
        return (
            <Route
                {...restOfProps}
                render={(props) =>
                    <Redirect to={AuthRoute.OORT_LOGIN} />
                }
            />
        );
    }
    try {
        const isAuthenticated = decoded.user_id.length > 0 && decoded.aud[0]==="metricsviews:auth"
        return (
            <Route
                {...restOfProps}
                render={(props) =>
                    isAuthenticated ? <Component {...props} /> : <Redirect to={AuthRoute.OORT_LOGIN} />
                }
            />
        );
    } catch (error) {
        return (
            <Route
                {...restOfProps}
                render={(props) =>
                    <Redirect to={AuthRoute.OORT_LOGIN} />
                }
            />
        );
    }
}

export function setHeaders() {
    const headers = {}
    const token = getJwtToken()
    if (token) headers["Authorization"] = `Bearer ${token}`
    return headers
}

export function logoutUser() {
    sessionStorage.clear();
    return AuthRoute.OORT_LOGIN
}

export const service = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 5000,
})

