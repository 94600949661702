import React, { useState, useEffect } from 'react'
import { Row, Col, Nav } from 'react-bootstrap'

import { bindActionCreators } from "redux"
//circular
import Circularprogressbar from '../../../components/circularprogressbar'


// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//Btn effect 
import { EffectFade } from 'swiper';

// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../store/setting/setting'
import { connect } from "react-redux"

//Auth Route
import { useHistory } from "react-router-dom";
import AuthRoute from '../../../_helpers/route'


// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})

const BtnCldElements = (props) => {
    const [show, AccountShow] = useState('A');

    const history = useHistory();
    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 1,
            once: true,
            duration: 700,
            offset: 10
        });
        //   customizer
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);

        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);

        }


    })

    const onClickOortEstimator = () => {
        history.push(AuthRoute.OORT_ESTIMATOR)
    }

    const onClickComingSoon = () => {
        history.push(AuthRoute.COMING_SOON)

    }


    return (
        <>
            <Row>
                <Col md="12" lg="12">
                    <Row className="row-cols-1">
                        <div className="overflow-hidden d-slider1 ">
                            <Swiper className="p-0 m-0 mb-2 list-inline"
                                slidesPerView={3}
                                spaceBetween={20}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    550: { slidesPerView: 2 },
                                    991: { slidesPerView: 3 },
                                    1400: { slidesPerView: 4 },
                                    1500: { slidesPerView: 5 },
                                    1920: { slidesPerView: 6 },
                                    2040: { slidesPerView: 7 },
                                    2440: { slidesPerView: 8 }
                                }}
                            >
                                {/* <SwiperSlide className={` ${show === 'Image' ? ' active done' : ''} ${show === 'slide2' ? ' active done' : ''} ${show === 'slide1' ? ' active done' : ''} ${show === 'A' ? 'active' : ''} card card-slide text-start active`} id="slide1"> */}
                                <SwiperSlide className=" card card-slide" id="slide1">
                                    <Nav.Link className="card-body" onClick={onClickOortEstimator}>
                                        <div className="progress-widget" >
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" Linecap='rounded' trailstroke='#ddd' strokewidth="4px" style={{ width: 60, height: 60, }} value={100} id="circle-progress-01" >
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-cpu" viewBox="0 0 16 16">
                                                    <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                                </svg>
                                            </Circularprogressbar>

                                                <div className="progress-detail" >
                                                <h4 className="counter" >Compute</h4>
                                                </div>

                                        </div>
                                    </Nav.Link>

                                </SwiperSlide>
                                <SwiperSlide className=" card card-slide" id="slide2">
                                    <Nav.Link className="card-body" onClick={onClickComingSoon}>
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={100} id="circle-progress-02" >
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-server" viewBox="0 0 16 16">
                                                    <path d="M1.333 2.667C1.333 1.194 4.318 0 8 0s6.667 1.194 6.667 2.667V4c0 1.473-2.985 2.667-6.667 2.667S1.333 5.473 1.333 4V2.667z" />
                                                    <path d="M1.333 6.334v3C1.333 10.805 4.318 12 8 12s6.667-1.194 6.667-2.667V6.334a6.51 6.51 0 0 1-1.458.79C11.81 7.684 9.967 8 8 8c-1.966 0-3.809-.317-5.208-.876a6.508 6.508 0 0 1-1.458-.79z" />
                                                    <path d="M14.667 11.668a6.51 6.51 0 0 1-1.458.789c-1.4.56-3.242.876-5.21.876-1.966 0-3.809-.316-5.208-.876a6.51 6.51 0 0 1-1.458-.79v1.666C1.333 14.806 4.318 16 8 16s6.667-1.194 6.667-2.667v-1.665z" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h4 className="counter">Database</h4>
                                            </div>
                                        </div>
                                    </Nav.Link >
                                </SwiperSlide>
                                <SwiperSlide className=" card card-slide" id="slide3">
                                    <Nav.Link className="card-body" onClick={onClickComingSoon}>
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={100} id="circle-progress-03" >
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-sd-card" viewBox="0 0 16 16">
                                                    <path d="M6.25 3.5a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2z" />
                                                    <path fillRule="evenodd" d="M5.914 0H12.5A1.5 1.5 0 0 1 14 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5V3.914c0-.398.158-.78.44-1.06L4.853.439A1.5 1.5 0 0 1 5.914 0zM13 1.5a.5.5 0 0 0-.5-.5H5.914a.5.5 0 0 0-.353.146L3.146 3.561A.5.5 0 0 0 3 3.914V14.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-13z" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h4 className="counter">Storage</h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </SwiperSlide>
                                <SwiperSlide className=" card card-slide" id="slide4">
                                    <Nav.Link className="card-body" onClick={onClickComingSoon}>
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={100} id="circle-progress-04" >
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-boxes" viewBox="0 0 16 16">
                                                    <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail" id="slide5">
                                                <h4 className="counter">Containers</h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </SwiperSlide>
                                <SwiperSlide className=" card card-slide" id="slide6">
                                    <Nav.Link className="card-body" onClick={onClickComingSoon}>
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.colorprimarymode} width="60px" height="60px" trailstroke='#ddd' strokewidth="4px" Linecap='rounded' style={{ width: 60, height: 60, }} value={100} id="circle-progress-05" >
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-asterisk" viewBox="0 0 16 16">
                                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h4 className="counter">Kubernetes</h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </SwiperSlide>
                                <SwiperSlide className=" card card-slide" id="slide7" >
                                    <Nav.Link className="card-body" onClick={onClickComingSoon}>
                                        <div className="progress-widget">
                                            <Circularprogressbar stroke={props.cololrinfomode} width="60px" height="60px" trailstroke='#ddd' Linecap='rounded' strokewidth="4px" value={100} style={{ width: 60, height: 60, }} id="circle-progress-06">
                                                <svg width="30" height="30" fill="currentColor" className="bi bi-hdd-network" viewBox="0 0 16 16">
                                                    <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a.5.5 0 0 1 0 1H10A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5H.5a.5.5 0 0 1 0-1H6A1.5 1.5 0 0 1 7.5 10V7H2a2 2 0 0 1-2-2V4zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1zm6 7.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z" />
                                                </svg>
                                            </Circularprogressbar>
                                            <div className="progress-detail">
                                                <h4 className="counter">Serverless</h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </SwiperSlide>

                                <div className="swiper-button swiper-button-next"></div>
                                <div className="swiper-button swiper-button-prev"></div>
                            </Swiper>
                        </div>
                    </Row>
                </Col>

            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(BtnCldElements)
