import React, { useEffect } from 'react'
import { bindActionCreators } from "redux"
import SettingOffcanvas from '../../../components/partials/components/settingoffcanvas'
import Loader from '../../../components/Loader'
import Card from '../../../components/Card'



import BtnCldElements from './btncldelements'



// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../store/setting/setting'
import { connect } from "react-redux"

//header
import Header from '../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../components/partials/dashboard/FooterStyle/footer'


// img
import shap1 from '../../../assets/images/shapes/01.png'
import shap2 from '../../../assets/images/shapes/02.png'
import shap3 from '../../../assets/images/shapes/03.png'
import shap4 from '../../../assets/images/shapes/04.png'
import shap5 from '../../../assets/images/shapes/05.png'
import shap6 from '../../../assets/images/shapes/06.png'


//progressbar
import Progress from '../../../components/partials/components/progress'
import { Link } from 'react-router-dom'


import { Row, Col, Image } from 'react-bootstrap'

// import oort search history 
import OortHistory from './oorthistory'






const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),

    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})




const OortHome = (props) => {
    // const history = useHistory();
    // const [userProfile, setuserProfile] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState();

    useEffect(() => {
        //   darkmode
        const colorMode = sessionStorage.getItem('color-mode');
        if (colorMode === null) {
            props.ModeAction(props.darkMode);
        }
        else {
            props.ModeAction(colorMode);
        }

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        // rtlmode
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.SchemeDirAction(props.schemeDirMode)
        }
        else {
            props.SchemeDirAction(rtlMode);
        }

        // if (userProfile.first_name !== undefined && userProfile.last_name !== undefined && userProfile.company_name !== undefined && userProfile.first_name !== null && userProfile.last_name !== null && userProfile.company_name !== null) {
        //     sessionStorage.setItem('company_name', CapitalizeFirstLetter(userProfile.company_name))
        // }





    },[props])

    return (
        <>
            <Loader />
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    < Header />
                    <SubHeader />
                    <div className="py-0 conatiner-fluid content-inner mt-n5" >
                        <BtnCldElements />

                        <div>
                            <OortHistory />
                            {/* <h1>test</h1> */}
                        </div>
                    </div>






                </div>
                <Row>

                </Row>

                <Footer />
            </main>
            <SettingOffcanvas />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(OortHome)
