import React, { useState, useEffect } from 'react'
// import FsLightbox from 'fslightbox-react';
import api from '../../../api/api';

import { Row, Col, Image, Form, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'


// import mvlogosmall from '../../../assets/images/mvbrands/mvlogosmall.svg'

// import { Link } from 'react-router-dom'
// img 

import avatars11 from '../../../assets/images/avatars/01.png'
import avatars22 from '../../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../../assets/images/avatars/avtar_5.png'


//header
import Header from '../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../components/partials/dashboard/FooterStyle/footer'
//Loader
import Loader from '../../../components/Loader'
//setting of canvas 
import SettingOffcanvas from '../../../components/partials/components/settingoffcanvas'
//capitalize
import { CapitalizeFirstLetter } from '../../../utils/capitalize';
//use history 
import { useHistory } from "react-router-dom";
// eslint-disable-next-line
import AuthRoute from '../../../_helpers/route';

const UserProfile = () => {
   const [userProfile, setuserProfile] = useState([]);
   // eslint-disable-next-line
   const [loading, setLoading] = useState(true);
   // eslint-disable-next-line
   const [error, setError] = useState();
   // eslint-disable-next-line
   const history = useHistory()


   useEffect(() => {

      const getUser = () => {
         try {
            api.UserprofileGet()
               .then(response => {
                  if (response.data.length !== 0) {
                     setuserProfile(response.data)
                     setLoading(false);
                     setError(null)
                  } 
               })
         } catch (err) {
            setError(err);
            setuserProfile(null);
         }
         finally {
            setLoading(false);
         }

      }
      getUser()
   }, []) // eslint-disable-line react-hooks/exhaustive-deps


   if (userProfile.length !== 0) {
      sessionStorage.setItem('first_name', userProfile.first_name !== null ? CapitalizeFirstLetter(userProfile.first_name) : "Howdy")
      sessionStorage.setItem('last_name', userProfile.last_name !== null ? CapitalizeFirstLetter(userProfile.last_name) : "Last Name")
      sessionStorage.setItem('company_name', userProfile.company_name !== null ? CapitalizeFirstLetter(userProfile.company_name) : "Comapany Name")
      sessionStorage.setItem('additional_details', userProfile.additional_details !== null ? CapitalizeFirstLetter(userProfile.additional_details) : "Additional Details")
      sessionStorage.setItem('email', userProfile.email)
      sessionStorage.setItem('company_id', userProfile.company_id)
      sessionStorage.setItem('id', userProfile.id)
   }

   const onClickUserEdit = () => {
      history.push(AuthRoute.USER_ACCOUNT_SETTING)
   }

   const onClickComBtnMenu = () => {
      history.push(AuthRoute.OORT_DASHBOARD)
   }


   return (
      <>
         <Loader />
         <Sidebar />
         <main className="main-content">

            < Header />
            <SubHeader />
            <div className="py-0 conatiner-fluid content-inner mt-n5">

               <Tab.Container defaultActiveKey="first">
                  <Row>
                     <Col lg="12">
                        <Card>
                           <Card.Body>
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                 <div className="d-flex flex-wrap align-items-center">
                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                       <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                       <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100" src={avatars22} alt="profile-pic" />
                                       <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100" src={avatars33} alt="profile-pic" />
                                       <Image className="theme-color-green-img img-fluid rounded-pill avatar-100" src={avatars55} alt="profile-pic" />
                                       <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100" src={avatars66} alt="profile-pic" />
                                       <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100" src={avatars44} alt="profile-pic" />
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                       {/* <h6 className="mb-0 caption-title">{sessionStorage.getItem('first_name')}</h6> */}

                                       <h4 className="mb-0 caption-sub-title">{sessionStorage.getItem('first_name')} </h4>
                                       <h4 className="me-2 h4">{"\u00a0"}{sessionStorage.getItem('last_name')}</h4>
                                       <h3 className="me-2 h4"> - {sessionStorage.getItem('company_name')}</h3>

                                    </div>
                                 </div>
                                 <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                    {/* <Nav.Item as="li">
                                    <Nav.Link eventKey="first">Feed</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item as="li">
                                       <Nav.Link eventKey="second" onClick={onClickComBtnMenu}>Search History</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item as="li">
                                    <Nav.Link eventKey="third">Friends</Nav.Link>
                                 </Nav.Item> */}
                                 <Nav.Item as="li">
                                       <Nav.Link eventKey="fourth" onClick={onClickUserEdit}>Edit Profile</Nav.Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>

                     <Col lg="12" >

                        <div>
                           <Row>

                              <Col xl="12" lg="8">
                                 <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                       <div className="header-title">
                                          <h4 className="card-title">User Information</h4>
                                       </div>
                                    </Card.Header>
                                    <Card.Body>
                                       <div className="new-user-info">
                                          <form>
                                             <div className="row">
                                                <Form.Group className="col-md-6 form-group">
                                                   <Form.Label htmlFor="fname">First Name:</Form.Label>
                                                   <Form.Control type="text" id="fname" placeholder={sessionStorage.getItem('first_name')} disabled />

                                                </Form.Group>
                                                <Form.Group className="col-md-6 form-group">
                                                   <Form.Label htmlFor="lname">Last Name:</Form.Label>
                                                   <Form.Control type="text" id="lname" placeholder={sessionStorage.getItem('last_name')} disabled />
                                                </Form.Group>


                                                <Form.Group className="col-md-6 form-group">
                                                   <Form.Label htmlFor="cname">Company Name:</Form.Label>
                                                   <Form.Control type="text" id="cname" placeholder={sessionStorage.getItem('company_name')} disabled />
                                                </Form.Group>
                                                <Form.Group className="col-md-6 form-group">
                                                   <Form.Label htmlFor="fname" >Additional Details:</Form.Label>
                                                   <Form.Control type="text" id="adddetails" placeholder={sessionStorage.getItem('additional_details')} disabled />

                                                </Form.Group>




                                             </div>
                                             <hr />
                                             <h5 className="mb-3">Security</h5>
                                             <div className="row">
                                                <Form.Group className="col-md-6  form-group">
                                                   <Form.Label htmlFor="email">Email:</Form.Label>
                                                   <Form.Control type="email" id="email" placeholder={sessionStorage.getItem('email')} disabled />
                                                </Form.Group>
                                                <Form.Group className="col-md-6 form-group">
                                                   <Form.Label>User Role:</Form.Label>
                                                   <Form.Control type="text" id="companyid" placeholder="Admin" disabled />
                                                </Form.Group>
                                                <Form.Group className="col-md-6  form-group">
                                                   <Form.Label htmlFor="text">Company Id:</Form.Label>
                                                   <Form.Control type="text" id="companyid" placeholder={sessionStorage.getItem('company_id')} disabled />
                                                </Form.Group>
                                                <Form.Group className="col-md-6  form-group">
                                                   <Form.Label htmlFor="text">User Id:</Form.Label>
                                                   <Form.Control type="text" id="userid" placeholder={sessionStorage.getItem('id')} disabled />
                                                </Form.Group>



                                             </div>


                                          </form>

                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Col>


                           </Row>
                        </div>




                     </Col>




                  </Row>
               </Tab.Container>
            </div>
            <Footer />
         </main>
         <SettingOffcanvas />
      </>
   )

}

export default UserProfile;