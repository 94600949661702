import React, { useEffect } from 'react'
import { bindActionCreators } from "redux"
// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../../../store/setting/setting'
import { connect } from "react-redux"
//header
import Header from '../../../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../../../components/partials/dashboard/FooterStyle/footer'
import Loader from '../../../../components/Loader'
import SettingOffcanvas from '../../../../components/partials/components/settingoffcanvas'


import { useHistory } from "react-router-dom";
import AuthRoute from '../../../../_helpers/route'
import { Row, Col, Image, Form, Nav, Dropdown, Tab, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import avatars11 from '../../../../assets/images/avatars/01.png'
import avatars22 from '../../../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../../../assets/images/avatars/avtar_5.png'


// wizard
import OortEstimator from '../oortestimator'






const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),

    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})




const MLAICinst = (props) => {
    const history = useHistory();

    useEffect(() => {
        //   darkmode
        const colorMode = sessionStorage.getItem('color-mode');
        if (colorMode === null) {
            props.ModeAction(props.darkMode);
        }
        else {
            props.ModeAction(colorMode);
        }

        // colocustomizermode
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);
        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);
        }

        // rtlmode
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.SchemeDirAction(props.schemeDirMode)
        }
        else {
            props.SchemeDirAction(rtlMode);
        }




    })

    const onClickComBtnMenu = () => {
        history.push(AuthRoute.COMPUTE_BTN_MENU)
    }


    return (
        <>
            <Loader />
            <Sidebar />
            <main className="main-content">
                <div className="position-relative">
                    < Header />
                    <SubHeader />
                    <div className="py-0 conatiner-fluid content-inner mt-n5">

                        <Tab.Container defaultActiveKey="first">
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                                        <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                                        <Image className="theme-color-purple-img img-fluid rounded-pill avatar-100" src={avatars22} alt="profile-pic" />
                                                        <Image className="theme-color-blue-img img-fluid rounded-pill avatar-100" src={avatars33} alt="profile-pic" />
                                                        <Image className="theme-color-green-img img-fluid rounded-pill avatar-100" src={avatars55} alt="profile-pic" />
                                                        <Image className="theme-color-yellow-img img-fluid rounded-pill avatar-100" src={avatars66} alt="profile-pic" />
                                                        <Image className="theme-color-pink-img img-fluid rounded-pill avatar-100" src={avatars44} alt="profile-pic" />
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                        {/* <h6 className="mb-0 caption-title">{sessionStorage.getItem('first_name')}</h6> */}

                                                        <h4 className="mb-0 caption-sub-title">{sessionStorage.getItem('first_name')} </h4>
                                                        <h4 className="me-2 h4">{"\u00a0"}{sessionStorage.getItem('last_name')}</h4>
                                                        <h3 className="me-2 h4"> - {sessionStorage.getItem('company_name')}</h3>

                                                    </div>
                                                </div>
                                                <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">

                                                    <Nav.Item as="li">
                                                        <Nav.Link eventKey="second" onClick={onClickComBtnMenu}>Menu</Nav.Link>
                                                    </Nav.Item>

                                                </Nav>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg="12" >

                                    <div>
                                        <Row>

                                            <Col lg="12">
                                                <OortEstimator></OortEstimator>
                                            </Col>


                                        </Row>
                                    </div>




                                </Col>




                            </Row>
                        </Tab.Container>
                    </div>







                </div>

                <Footer />
            </main>
            <SettingOffcanvas />




        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MLAICinst)
